import React from 'react';
import './App.css';
import styled from 'styled-components';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/fira-mono/400.css';
import '@fontsource/fira-mono/500.css';
import '@fontsource/fira-mono/700.css';
import { FormulaBar, Worksheet, Navigation, Toolbar, Root } from './components/workbook';

const App = () => (
  <div className="App">
    <header className="App-header">
      <div style={{ width: '100%', height: '100%', overflow: 'visible' }}>
        <WorkbookRoot>
          <Toolbar />
          <FormulaBar />
          <Worksheet />
          <Navigation />
        </WorkbookRoot>
      </div>
    </header>
  </div>
);

export default App;

const WorkbookRoot = styled(Root)`
  border: 1px solid #c6cae3;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 2px rgba(33, 36, 58, 0.15));
`;
