import React from 'react';

export const DecimalPlacesDecreaseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
    <path
      fill="#21243A"
      d="M2.465 10.093a.858.858 0 0 1-.625-.257.858.858 0 0 1-.257-.624c0-.24.086-.445.257-.617a.858.858 0 0 1 .625-.257c.235 0 .44.086.617.257a.83.83 0 0 1 .264.617c0 .162-.042.31-.125.444a.95.95 0 0 1-.32.32.841.841 0 0 1-.436.117ZM7.261 10.163c-.631-.002-1.175-.158-1.63-.466-.453-.309-.802-.755-1.047-1.34-.242-.586-.362-1.29-.36-2.112 0-.82.122-1.519.364-2.096.245-.578.594-1.018 1.046-1.319.456-.303.998-.455 1.627-.455.63 0 1.17.152 1.623.455.455.304.805.744 1.05 1.322.245.575.366 1.273.364 2.093 0 .825-.123 1.53-.367 2.115-.243.585-.59 1.032-1.043 1.34-.453.309-.995.463-1.627.463Zm0-1.318c.43 0 .775-.217 1.032-.65.257-.433.384-1.083.382-1.95 0-.57-.059-1.045-.176-1.425-.116-.379-.28-.664-.493-.855a1.073 1.073 0 0 0-.745-.286c-.428 0-.771.214-1.028.642-.257.429-.387 1.07-.39 1.924 0 .578.058 1.06.173 1.447.118.384.283.673.496.867.213.19.463.286.75.286Z"
    />
    <path
      stroke="#21243A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.545 14.21h-9.42m0 0 2.295-2.295M7.125 14.21l2.295 2.296"
    />
  </svg>
);

export const DecimalPlacesIncreaseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
    <path
      stroke="#21243A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M7.205 14.25h9.42m0 0-2.295-2.296m2.295 2.296-2.295 2.295"
    />
    <path
      fill="#21243A"
      d="M2.465 10.093a.858.858 0 0 1-.625-.257.858.858 0 0 1-.257-.624c0-.24.086-.445.257-.617a.858.858 0 0 1 .625-.257c.235 0 .44.086.617.257a.83.83 0 0 1 .264.617c0 .162-.042.31-.125.444a.95.95 0 0 1-.32.32.841.841 0 0 1-.436.117ZM7.261 10.163c-.631-.002-1.175-.158-1.63-.466-.453-.309-.802-.755-1.047-1.34-.242-.586-.362-1.29-.36-2.112 0-.82.122-1.519.364-2.096.245-.578.594-1.018 1.046-1.319.456-.303.998-.455 1.627-.455.63 0 1.17.152 1.623.455.455.304.805.744 1.05 1.322.245.575.366 1.273.364 2.093 0 .825-.123 1.53-.367 2.115-.243.585-.59 1.032-1.043 1.34-.453.309-.995.463-1.627.463Zm0-1.318c.43 0 .775-.217 1.032-.65.257-.433.384-1.083.382-1.95 0-.57-.059-1.045-.176-1.425-.116-.379-.28-.664-.493-.855a1.073 1.073 0 0 0-.745-.286c-.428 0-.771.214-1.028.642-.257.429-.387 1.07-.39 1.924 0 .578.058 1.06.173 1.447.118.384.283.673.496.867.213.19.463.286.75.286ZM14.38 10.163c-.632-.002-1.175-.158-1.63-.466-.453-.309-.802-.755-1.047-1.34-.242-.586-.362-1.29-.36-2.112 0-.82.121-1.519.364-2.096.245-.578.593-1.018 1.046-1.319.456-.303.998-.455 1.627-.455.63 0 1.17.152 1.623.455.455.304.805.744 1.05 1.322.245.575.366 1.273.364 2.093 0 .825-.123 1.53-.368 2.115-.242.585-.59 1.032-1.042 1.34-.453.309-.995.463-1.627.463Zm0-1.318c.43 0 .775-.217 1.032-.65.257-.433.384-1.083.382-1.95 0-.57-.06-1.045-.177-1.425-.115-.379-.279-.664-.492-.855a1.073 1.073 0 0 0-.745-.286c-.428 0-.771.214-1.028.642-.257.429-.387 1.07-.39 1.924 0 .578.058 1.06.173 1.447.118.384.283.673.496.867.213.19.463.286.749.286Z"
    />
  </svg>
);
